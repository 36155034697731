<template>
  <router-view/>
</template>
<script>
export default {
  name: 'App',
  created() {
    // this.mobileAdapter()
  },
  methods: {
    mobileAdapter() {
      const WIDTH = 667
      const mobileAdapter = () => {
        let scale = screen.width/WIDTH
        let content = `width=${WIDTH}, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}`
        let meta = document.querySelector('meta[name=viewport]')
        if(!meta) {
          meta = document.createElement('meta')
          meta.setAttribute('name', 'viewport')
          document.head.appendChild(meta)
        }
        meta.setAttribute('content', content)
      }
      mobileAdapter()
      window.onorientationchange = mobileAdapter
    }
  }
}
</script>
<style lang="less">

@import '@/assets/css/animate.min.css';
@import "@/assets/css/reset.less";
@import "@/assets/css/animate.min.css";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

</style>
