<template>
  <div id="homePage" :class="lang">
    <div class="loading-page" v-if="active === -1"><div class="loading"></div></div>
    <!--首页-->
    <div class="page home-page" :class="{'active': active === 0}">
      <!--背景人物月亮...-->
      <div class="bg">
        <!--<img src="../assets/images/home-page/common/cloud-left.png">-->
        <!--<img src="../assets/images/home-page/common/cloud-right.png">-->
        <!--<img src="../assets/images/home-page/common/cloud-bottom.png" class="cloud-bottom">-->
        <!--<img src="../assets/images/home-page/common/moon.png" class="moon">-->
        <!--<img src="../assets/images/home-page/common/tower.png" class="tower">-->
      </div>
      <div class="lf"></div>
      <div class="cn">
        <img class="title animated zoomIn" :src="require('../assets/images/home-page/' + lang + '/src-index-title.png')">
        <div class="btn animated fadeInUp delay-05">
          <img v-if="lang === 'cn'" src="../assets/images/home-page/btn/btn-cn-active.png" @click="changeLang('cn')">
          <img v-else src="../assets/images/home-page/btn/btn-cn.png" @click="changeLang('cn')">
          <img v-if="lang === 'en'" src="../assets/images/home-page/btn/btn-en-active.png" @click="changeLang('en')">
          <img v-else src="../assets/images/home-page/btn/btn-en.png" @click="changeLang('en')">
          <!--<img src="../assets/images/home-page/btn/btn-e.png">-->
          <!--<img src="../assets/images/home-page/btn/btn-alb.png">-->
        </div>
        <img class="start-btn animated fadeInUp delay-1" :src="require('../assets/images/home-page/' + lang + '/btn-index-start.png')" @click="jump(1)">
        <img v-if="lang !== 'en'" class="footer animated fadeInUp delay-15" :src="require('../assets/images/home-page/' + lang + '/src-index-footer.png')">
      </div>
      <div class="rg"></div>
    </div>
    <!--前言-->
    <div class="page foreword-page" :class="{'active': active === 1}">
      <img class="main animated zoomIn" :src="require('../assets/images/foreword-page/' + lang + '/src-infor-main.png')">
      <img class="btn" :src="require('../assets/images/foreword-page/' + lang + '/btn-infor-more.png')" @click="jump(2)">
    </div>
    <!--地图-->
    <div class="page map-page" :class="{'active': active === 2}">
      <img class="title animated fadeInLeft" :src="require('../assets/images/map-page/title.png')">
      <div>
        <img class="map animated fadeInRight delay-05" :src="require('../assets/images/map-page/' + lang + '/map.png')">
        <div class="btn">
          <img class="map-1 animated fadeInUp delay-1" :class="lang" :src="require('../assets/images/map-page/' + lang + '/btn-map-dx.png')" @click="jump(9)">
          <img class="map-2 animated fadeInUp delay-15" :class="lang" :src="require('../assets/images/map-page/' + lang + '/btn-map-dh.png')" @click="jump(7)">
          <img class="map-3 animated fadeInUp delay-2" :class="lang" :src="require('../assets/images/map-page/' + lang + '/btn-map-ww.png')" @click="jump(5)">
          <img class="map-4 animated fadeInDown delay-25" :class="lang" :src="require('../assets/images/map-page/' + lang + '/btn-map-ln.png')" @click="jump(3)">
        </div>
      </div>
    </div>
    <!--章节 秦-->
    <div class="page zhangjie-page" :class="{'active': active === 3,}">
      <p v-if="lang === 'cn'">您是第{{visitor}}位访问者</p>
      <p v-else-if="lang === 'en'"> Your are visitor No{{visitor}}</p>
      <p v-else-if="lang === 'e'"> Вы - {{visitor}}-й посетитель</p>
      <p v-else-if="lang === 'alb'">إنك أنت الزائر ال_{{visitor}}</p>
    </div>
    <!--诗词 秦-->
    <div class="page shici-page animated fadeIn" :class="{'active': active === 4}">
      <img class="btn" :src="require('../assets/images/foreword-page/' + lang + '/btn-infor-more.png')" @click="jump(11, '秦')">
    </div>
    <!--章节 汉 -->
    <div class="page zhangjie-page han" :class="{'active': active === 5}">
      <p v-if="lang === 'cn'">您是第{{visitor}}位访问者</p>
      <p v-else-if="lang === 'en'"> Your are visitor No{{visitor}}</p>
      <p v-else-if="lang === 'e'"> Вы - {{visitor}}-й посетитель</p>
      <p v-else-if="lang === 'alb'">إنك أنت الزائر ال_{{visitor}}</p>
    </div>
    <!--诗词 汉-->
    <div class="page shici-page han animated fadeIn" :class="{'active': active === 6}">
      <img class="btn" :src="require('../assets/images/foreword-page/' + lang + '/btn-infor-more.png')" @click="jump(11, '汉')">
    </div>
    <!--章节 唐-->
    <div class="page zhangjie-page tang" :class="{'active': active === 7}">
      <p v-if="lang === 'cn'">您是第{{visitor}}位访问者</p>
      <p v-else-if="lang === 'en'"> Your are visitor No{{visitor}}</p>
      <p v-else-if="lang === 'e'"> Вы - {{visitor}}-й посетитель</p>
      <p v-else-if="lang === 'alb'">إنك أنت الزائر ال_{{visitor}}</p>
    </div>
    <!--诗词 唐-->
    <div class="page shici-page tang animated fadeIn" :class="{'active': active === 8}">
      <img class="btn" :src="require('../assets/images/foreword-page/' + lang + '/btn-infor-more.png')" @click="jump(11, '唐')">
    </div>
    <!--章节 宋-->
    <div class="page zhangjie-page song" :class="{'active': active === 9}">
      <p v-if="lang === 'cn'">您是第{{visitor}}位访问者</p>
      <p v-else-if="lang === 'en'"> Your are visitor No{{visitor}}</p>
      <p v-else-if="lang === 'e'"> Вы - {{visitor}}-й посетитель</p>
      <p v-else-if="lang === 'alb'">إنك أنت الزائر ال_{{visitor}}</p>
    </div>
    <!--诗词 宋-->
    <div class="page shici-page song animated fadeIn" :class="{'active': active === 10}">
      <img class="btn" :src="require('../assets/images/foreword-page/' + lang + '/btn-infor-more.png')" @click="jump(11, '宋')">
    </div>
    <!--轮播图-->
    <div class="page swiper-page" :class="{'active': active === 11}">
      <div class="overlay"></div>
      <img src="../assets/images/swiper-page/back-btn.png" class="go-back" @click="jump('back')">
      <div class="relative">
        <img src="../assets/images/swiper-page/btn-pre.png" class="pre">
        <swiper
            class="swiper" v-if="active === 11" ref="mySwiper"
            @swiper="onSwiper"
            :navigation="navigation"
            :lazy="true">
          <swiper-slide
              :navigation="navigation" effect="fade" :direction="'vertical'"
              v-for="(item, index) in swiper" :key="index"><img :src="require('../assets/images/swiper-page/' + lang + '/' + dynasty + '/' + (index + 1) + '.png')" class="pic"></swiper-slide>
        </swiper>
        <img src="../assets/images/swiper-page/btn-next.png" class="next">
      </div>
      <div class="flex-r">
        <img :src="require('../assets/images/swiper-page/' + lang + '/btn/btn-back.png')" class="back-btn" @click="jump(2)">
        <img :src="require('../assets/images/swiper-page/' + lang + '/btn/btn-qa.png')" class="q-btn" @click="jump(12)">
      </div>
    </div>
    <!--互动留言-->
    <div class="page question-page" :class="{'active': active === 12}">
      <img src="../assets/images/question-page/title-qa.png" class="title">
      <img :src="require('../assets/images/question-page/' + lang + '/src-word-fir.png')" class="question">
      <div class="input"><textarea v-model="question1"></textarea></div>
      <img :src="require('../assets/images/question-page/' + lang + '/src-word-two.png')" class="question">
      <div class="input"><textarea v-model="question2"></textarea></div>
      <img :src="require('../assets/images/question-page/' + lang + '/btn-mxp-message.png')" class="btn" @click="leaveMessage">
      <div class="tip animated fadeIn" v-if="messageTip">{{messageTip}}</div>
    </div>
    <!--尾页保存图片-->
    <div class="page save-card-page" :class="{'active': active === 13}">
      <div class="overlay"></div>
      <div class="loading-box" v-if="pic === ''">
        <div class="loading"></div>
      </div>
      <div class="card" id="saveImg">
        <img v-if="pic" :src="pic" class="save-pic">
        <div v-else>
          <div class="img-box">
            <img :src="cardImg" class="pic">
          </div>
          <div class="flex-r">
            <div>
              <div class="wechat">
                <img :src="wchatImg" class="pic">{{wchatName}}
              </div>
              <p v-if="lang === 'cn'">恭喜，您是第{{visitor}}位访客，在线浏览了甘肃历史风景...</p>
              <p v-else-if="lang === 'en'">Congratulations on the visitor No5 to browse the historic landscape of Gansu</p>
            </div>
            <div class="code">
              <img src="../assets/images/card-page/code-gov.png">
              <img src="../assets/images/card-page/code-org.png">
            </div>
          </div>
        </div>
      </div>
      <img :src="require('../assets/images/card-page/' + lang + '/btn-mxp-save.png')" class="btn">
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image'
import axios from 'axios'
// import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, Lazy } from 'swiper'
SwiperCore.use([Pagination, Navigation, Lazy])
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/less/lazy'

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    // EffectFade,
  },
  data () {
    return {
      lang: 'cn',
      Navigation,
      Pagination,
      active: -1,
      visitor: 3,
      question1: '',
      question2: '',
      pic: '',
      dynasty: '唐',
      navigation: {
        nextEl: '.next',
        prevEl: '.pre',
      },
      // pagination: {
      //   clickable: true,
      //   bulletClass: 'dot',
      //   bulletActiveClass: 'actived',
      //   el: '.pagination',
      // },
      swiperOption: {
        // slidesPerView: 1,
        loop: true, // 循环模式
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        speed: 2000,
        effect: 'fade',
        // 显示分页
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true //允许分页点击跳转
        // },
        // 设置点击箭头
        navigation: {
          nextEl: ".next",
          prevEl: ".prev"
        }
      },
      swiper: [],
      swiperTang: [
        require('../assets/images/swiper-page/cn/唐/1.png'),
        require('../assets/images/swiper-page/cn/唐/2.png'),
        require('../assets/images/swiper-page/cn/唐/3.png'),
        require('../assets/images/swiper-page/cn/唐/4.png'),
        require('../assets/images/swiper-page/cn/唐/5.png'),
        require('../assets/images/swiper-page/cn/唐/6.png'),
        require('../assets/images/swiper-page/cn/唐/7.png'),
        require('../assets/images/swiper-page/cn/唐/8.png'),
        require('../assets/images/swiper-page/cn/唐/9.png'),
        require('../assets/images/swiper-page/cn/唐/10.png'),
        require('../assets/images/swiper-page/cn/唐/11.png'),
      ],
      swiperHan: [
        require('../assets/images/swiper-page/cn/汉/1.png'),
        require('../assets/images/swiper-page/cn/汉/2.png'),
        require('../assets/images/swiper-page/cn/汉/3.png'),
        require('../assets/images/swiper-page/cn/汉/4.png'),
        require('../assets/images/swiper-page/cn/汉/6.png'),
        require('../assets/images/swiper-page/cn/汉/7.png'),
        require('../assets/images/swiper-page/cn/汉/8.png'),
        require('../assets/images/swiper-page/cn/汉/9.png'),
        require('../assets/images/swiper-page/cn/汉/10.png'),
      ],
      swiperSong: [
        require('../assets/images/swiper-page/cn/宋/1.png'),
        require('../assets/images/swiper-page/cn/宋/2.png'),
        require('../assets/images/swiper-page/cn/宋/3.png'),
        require('../assets/images/swiper-page/cn/宋/4.png'),
        require('../assets/images/swiper-page/cn/宋/5.png'),
        require('../assets/images/swiper-page/cn/宋/6.png'),
        require('../assets/images/swiper-page/cn/宋/7.png'),
        require('../assets/images/swiper-page/cn/宋/8.png'),
        require('../assets/images/swiper-page/cn/宋/9.png'),
        require('../assets/images/swiper-page/cn/宋/10.png'),
        require('../assets/images/swiper-page/cn/宋/11.png'),
        require('../assets/images/swiper-page/cn/宋/12.png'),
        require('../assets/images/swiper-page/cn/宋/13.png'),
        require('../assets/images/swiper-page/cn/宋/14.png'),
        require('../assets/images/swiper-page/cn/宋/15.png'),
        require('../assets/images/swiper-page/cn/宋/16.png'),
        require('../assets/images/swiper-page/cn/宋/17.png'),
        require('../assets/images/swiper-page/cn/宋/18.png'),
        require('../assets/images/swiper-page/cn/宋/19.png'),
      ],
      swiperQIN: [
        require('../assets/images/swiper-page/cn/秦/1.png'),
        require('../assets/images/swiper-page/cn/秦/2.png'),
        require('../assets/images/swiper-page/cn/秦/3.png'),
        require('../assets/images/swiper-page/cn/秦/4.png'),
        require('../assets/images/swiper-page/cn/秦/5.png'),
        require('../assets/images/swiper-page/cn/秦/6.png'),
        require('../assets/images/swiper-page/cn/秦/7.png'),
        require('../assets/images/swiper-page/cn/秦/8.png'),
      ],
      saveCardList: ['saveCardTangList', 'saveCardHanList', 'saveCardSongList', 'saveCardQinList'],
      saveCardTangList: [
        require('../assets/images/card-page/card/唐/1.jpg'),
        require('../assets/images/card-page/card/唐/2.jpg'),
        require('../assets/images/card-page/card/唐/3.jpg'),
        require('../assets/images/card-page/card/唐/4.jpg'),
        require('../assets/images/card-page/card/唐/5.jpg'),
        require('../assets/images/card-page/card/唐/6.jpg'),
        require('../assets/images/card-page/card/唐/7.jpg'),
        require('../assets/images/card-page/card/唐/8.jpg'),
        require('../assets/images/card-page/card/唐/9.jpg'),
        require('../assets/images/card-page/card/唐/10.jpg'),
        require('../assets/images/card-page/card/唐/11.jpg'),
      ],
      saveCardHanList: [
        require('../assets/images/card-page/card/汉/1.jpg'),
        require('../assets/images/card-page/card/汉/2.jpg'),
        require('../assets/images/card-page/card/汉/3.jpg'),
        require('../assets/images/card-page/card/汉/4.jpg'),
        require('../assets/images/card-page/card/汉/5.jpg'),
        require('../assets/images/card-page/card/汉/6.jpg'),
        require('../assets/images/card-page/card/汉/7.jpg'),
        require('../assets/images/card-page/card/汉/8.jpg'),
        require('../assets/images/card-page/card/汉/9.jpg'),
        require('../assets/images/card-page/card/汉/10.jpg'),
        require('../assets/images/card-page/card/汉/11.jpg'),
      ],
      saveCardSongList: [
        require('../assets/images/card-page/card/宋/1.jpg'),
        require('../assets/images/card-page/card/宋/2.jpg'),
        require('../assets/images/card-page/card/宋/3.jpg'),
        require('../assets/images/card-page/card/宋/4.jpg'),
        require('../assets/images/card-page/card/宋/5.jpg'),
        require('../assets/images/card-page/card/宋/6.jpg'),
        require('../assets/images/card-page/card/宋/7.jpg'),
        require('../assets/images/card-page/card/宋/8.jpg'),
        require('../assets/images/card-page/card/宋/9.jpg'),
        require('../assets/images/card-page/card/宋/10.jpg'),
        require('../assets/images/card-page/card/宋/11.jpg'),
        require('../assets/images/card-page/card/宋/12.jpg'),
        require('../assets/images/card-page/card/宋/13.jpg'),
        require('../assets/images/card-page/card/宋/14.jpg'),
        require('../assets/images/card-page/card/宋/15.jpg'),
      ],
      saveCardQinList: [
        require('../assets/images/card-page/card/秦/1.jpg'),
        require('../assets/images/card-page/card/秦/2.jpg'),
        require('../assets/images/card-page/card/秦/3.jpg'),
        require('../assets/images/card-page/card/秦/4.jpg'),
        require('../assets/images/card-page/card/秦/5.jpg'),
        require('../assets/images/card-page/card/秦/6.jpg'),
        require('../assets/images/card-page/card/秦/7.jpg'),
        require('../assets/images/card-page/card/秦/8.jpg'),
      ],
      cardImg: '',
      wchatImg: require('../assets/images/card-page/code-gov.png'),
      wchatName: '微信昵称',
      messageTip: '',
    }
  },
  mounted () {
    this.getSaveImg()
    setTimeout(() => {
      this.active = 0
    }, 3000)

    this.swiper = this.swiperQIN
    this.resize()
    window.onresize = () => {
      this.resize()
    }
    // window.addEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      var width = window.innerWidth,
          height = window.innerHeight,
          $wrapper = document.getElementById("homePage"),
          style = "";
      if (width >= height) { // 横屏
        style += "width:" + width + "px;";  // 注意旋转后的宽高切换
        style += "height:" + height + "px;";
        style += "-webkit-transform: rotate(0); transform: rotate(0);";
        style += "-webkit-transform-origin: " + width / 2 + "px " + width / 2 + "px;";
        style += "transform-origin: " + width / 2 + "px " + width / 2 + "px;";
      }
      else { // 竖屏
        style += "width:" + height + "px;";
        style += "height:" + width + "px;";
        style += "-webkit-transform: rotate(90deg); transform: rotate(90deg);";
        // 注意旋转中点的处理
        style += "-webkit-transform-origin: " + width / 2 + "px " + width / 2 + "px;";
        style += "transform-origin: " + width / 2 + "px " + width / 2 + "px;";
      }
      $wrapper.style.cssText = style;
    },
    // 切换语言
    changeLang(type) {
      if (type === this.lang) return
      this.lang = type
    },
    getSaveImg() {
      let list = Math.floor((Math.random() * 4)) // 随机数
      let arr = this.saveCardList[list]
      let index = Math.floor((Math.random() * this[arr].length))
      this.cardImg = this[arr][index]
    },
    // 获取微信息接口请求
    getWechat() {
      axios.get('http://101.42.35.211:8077/wxlogin').then(response => {
        console.log(response)
      })
    },
    // 页面跳转
    jump(index, dynasty) {
      if (index === 11) {
        this.dynasty = dynasty
        if (this.dynasty === '唐') {
          this.swiper = this.swiperTang
        } else if (this.dynasty === '汉') {
          this.swiper = this.swiperHan
        } else if (this.dynasty === '宋') {
          this.swiper = this.swiperSong
        } else if (this.dynasty === '秦') {
          this.swiper = this.swiperQIN
        }
      }
      console.log(this.dynasty, ' dynasty')
      if (index === 'back') {
        if (this.dynasty === '唐') {
          this.active = 8
        } else if (this.dynasty === '宋') {
          this.active = 10
        } else if (this.dynasty === '汉') {
          this.active = 6
        } else if (this.dynasty === '秦') {
          this.active = 4
        }
      } else {
        this.active = index
      }
      if (index === 3 || index === 5 || index === 7 || index === 9) { // 章节跳转诗词
        setTimeout(() => {
          this.active = index + 1
        }, 5000)
      }
    },
    onSwiper (swiper) {
      console.log(swiper)
    },
      pre() {
      console.log('pre')
    },
    next() {
      console.log('next')
    },
    // 留言
    leaveMessage() {
      if (this.question1 === '' || this.question2 === '') {
        this.messageTip = this.lang === 'cn' ?'请填写完整信息' : 'Please fill in information'
        setTimeout(() => {
          this.messageTip = ''
        }, 1500)
      } else {
        this.saveCard()
      }
    },
    // 留言canvas 存图片
    saveCard() {
      let that = this
      this.active = 13
      setTimeout(() => {
        let saveImg = document.getElementById('saveImg')
        domtoimage.toSvg(saveImg, {quality: 2, scale: 2}).then(function (dataUrl) {
          that.pic = dataUrl
        }).catch(function (error) {
          console.error('oops, something went wrong!', error);
        })
      }, 2000)
    }
  }
}
</script>

<style lang="less" scoped>
  .loading-page {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }
  .page {
    position: absolute;
    display: none;
    z-index: 0;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.active {
      z-index: -1;
      display: flex;
    }
    &.home-page {
      background: #eebf71 url("@/assets/images/bg/home-bg.jpg") no-repeat center;
      background-size: cover;
      .cn {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title {
          //width: 426px;
          width: 490px;
        }
        .btn {
          margin: 22px 0 26px;
          img {
            height: 20px;
            margin: 0 7px;
          }
        }
        .footer {
          width: 274px;
        }
        .start-btn {
          width: 163px;
        }
      }
      .bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        img {
          position: absolute;
          .cloud-bottom {
            bottom: 0;
            right: 50px;
            width: 200px;
          }
          .moon {
            width: 148px;
            left: 16px;
            top: 9px;
          }
        }
      }
    }
    &.foreword-page {
      background: #eebf71 url("@/assets/images/bg/foreword-bg.jpg") no-repeat center;
      background-size: cover;
      .main {
        width: 615px;
      }
      .btn {
        width: 184px;
        position: absolute;
        bottom: 20px;
      }
    }
    &.map-page {
      background: #eebf71 url("@/assets/images/bg/map-bg.jpg") no-repeat center bottom;
      background-size: cover;
      flex-direction: row;
      > div {
        position: relative;
      }
      .title {
        //width: 79px;
        width: 100px;
        margin-right: 28px;
      }
      .map {
        //width: 501px;
        width: 550px;
      }
      .btn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        img {
          position: absolute;
          &.map-1 {
            width: 62px;
            left: 41px;
            bottom: 29px;
            &.en {
              left: 30px;
              width: 150px;
              bottom: 89px;
            }
          }
          &.map-2 {
            width: 62px;
            left: 147px;
            bottom: 61px;
            &.en {
              width: 120px;
              bottom: 121px;
            }
          }
          &.map-3 {
            width: 102px;
            left: 221px;
            bottom: 35px;
            &.en {
              width: 120px;
              left: 210px;
              bottom: 45px;
            }
          }
          &.map-4 {
            width: 62px;
            right: 36px;
            top: 27px;
            &.en {
              width: 120px;
              top: 67px;
            }
          }
        }
      }
    }
    &.zhangjie-page {
      background: #eebf71 url("@/assets/images/shici-page/cn/src-ln.jpg") no-repeat center;
      &.han {
        background-image: url("@/assets/images/shici-page/cn/src-ww.jpg");
        background-color: #0d1913;
      }
      &.tang {
        background-image: url("@/assets/images/shici-page/cn/src-dh.jpg");
        background-color: #c6b59b;
      }
      &.song {
        background-image: url("@/assets/images/shici-page/cn/src-dx.jpg");
        background-color: #87cdfd;
      }
      background-size: cover;
      justify-content: flex-end;
      align-items: flex-start;
      img {
        width: 100%;
      }
      p {
        color: #ffffff;
        font-size: 14px;
        line-height: 1;
        margin: 36px 64px;
      }
    }
    &.shici-page {
      background: #eebf71 url("@/assets/images/shici-page/cn/src-ln-sc.jpg") no-repeat center;
      &.han {
        background-image: url("@/assets/images/shici-page/cn/src-ww-sc.jpg");
        background-color: #0d1913;
      }
      &.tang {
        background-image: url("@/assets/images/shici-page/cn/src-dh-sc.jpg");
        background-color: #c6b59b;
      }
      &.song {
        background-image: url("@/assets/images/shici-page/cn/src-dx-sc.jpg");
        background-color: #87cdfd;
      }
      background-size: cover;
      justify-content: flex-end;
      img {
        width: 100%;
      }
      .btn {
        margin-bottom: 20px;
        width: 184px;
      }
    }
    &.swiper-page {
      background: #eebf71 url("@/assets/images/bg/home-bg.png") no-repeat center;
      background-size: cover;
      .swiper {
        width: 580px;
        height: 345px;
        img {
          width: 100%;
        }
      }
      .overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
      }
      .go-back {
        width: 40px;
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 1;
      }
      .relative {
        display: flex;
        align-items: center;
        justify-content: center;
        .pre, .next {
          width: 70px;
          margin: 0 -20px;
          position: relative;
          z-index: 2;
        }
      }
      .back-btn, .q-btn {
        width: 148px;
        margin: 0 19px;
      }
    }
    &.question-page {
      background: #eebf71 url("@/assets/images/bg/home-bg.jpg") no-repeat center;
      background-size: cover;
      .tip {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -80px;
        margin-top: -20px;
        color: #ffffff;
        font-size: 14px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        min-width: 150px;
        text-align: center;
      }
      .title {
        width: 421px;
      }
      .question {
        width: 295px;
      }
      .input {
        width: 285px;
        height: 65px;
        margin-bottom: 16px;
        textarea {
          background: url("@/assets/images/question-page/bg-input-qa.png") no-repeat center;
          background-size: 100%;
          width: 100%;
          height: 100%;
          font-size: 14px;
          line-height: 1.2;
          padding: 10px;
          outline: none;
          resize: none;
          border: none;
          box-sizing: border-box;
        }
      }
      .btn {
        width: 184px;
      }
    }
    &.save-card-page {
      &.active {
        display: flex;
      }
      font-size: 14px;
      line-height: 1;
      .overlay {
        background: #eebf71 url("@/assets/images/bg/home-bg.jpg") no-repeat center;
        background-size: cover;
        filter:blur(5px);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
      .loading-box {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }
      .card {
        width: 467px;
        padding: 20px;
        background: #ffffff;
        border-radius: 10px;
        .img-box {
          max-height: 50vw;
          overflow: hidden;
        }
        .flex-r {
          justify-content: space-between;
          margin-top: 10px;
          .wechat {
            line-height: 1.3;
            img {
              width: 20px;
              height: 20px;
              background: red;
              margin-right: 5px;
            }
          }
          p {
            margin-top: 8px;
            font-size: 12px;
          }
        }
        .pic {
          width: 467px;
        }
        .save-pic {
          margin: -20px;
        }
        .code {
          display: flex;
          align-items: center;
          img {
            width: 50px;
            margin-left: 10px;
          }
        }
      }
      .btn {
        width: 184px;
        margin-top: 12px;
      }
    }
  }
  .en {
    .page {
      &.zhangjie-page {
        background-image: url("@/assets/images/shici-page/en/src-ln.jpg");
        &.han {
          background-image: url("@/assets/images/shici-page/en/src-ww.jpg");
        }
        &.tang {
          background-image: url("@/assets/images/shici-page/en/src-dh.jpg");
        }
        &.song {
          background-image: url("@/assets/images/shici-page/en/src-dx.jpg");
        }
      }
      &.shici-page {
        background-image: url("@/assets/images/shici-page/en/src-ln-sc.jpg");
        &.han {
          background-image: url("@/assets/images/shici-page/en/src-ww-sc.jpg");
        }
        &.tang {
          background-image: url("@/assets/images/shici-page/en/src-dh-sc.jpg");
        }
        &.song {
          background-image: url("@/assets/images/shici-page/en/src-dx-sc.jpg");
        }
      }
    }
  }
  .loading {
    width: 30px;
    height: 30px;
    border: 2px solid #ffffff;
    border-top-color: transparent;
    border-radius: 100%;

    animation: circle infinite 0.75s linear;
  }

  // 转转转动画
  @keyframes circle {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>

<style lang="less">
html {
  background-image:
      url("../assets/images/bg/home-bg.jpg"),
      url("../assets/images/bg/card-bg.jpg"),
      url("../assets/images/shici-page/cn/src-ln-sc.jpg");
  background-size:0 0;
}
body {
  background: #eebf71 url("@/assets/images/bg/foreword-bg.jpg") no-repeat center;
  background-size: cover;
}
.swiper-button-prev {
  width: 35px;
  height: 35px;
  background: url("@/assets/images/swiper-page/btn-pre.png") no-repeat center;
  background-size: cover;
  left: -3px;
  &::after {
   display: none;
 }
}
.swiper-button-next {
  width: 35px;
  height: 35px;
  background: url("@/assets/images/swiper-page/btn-next.png") no-repeat center;
  background-size: cover;
  right: -3px;
  &::after {
   display: none;
 }
}
</style>